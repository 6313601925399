type PositionTypes = {
  lat: number
  lng: number
}

type DistanceFilterType = {
  position1: PositionTypes | undefined
  position2: PositionTypes
  distance: number
}

const rad = (x: number): number => {
  return (x * Math.PI) / 180
}

const getDistance = (
  position1: PositionTypes,
  position2: PositionTypes,
): number => {
  const earthMeanRadius = 6378137
  const distanceLat = rad(position2.lat - position1.lat)
  const distanceLong = rad(position2.lng - position1.lng)
  const a =
    Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
    Math.cos(rad(position1.lat)) *
      Math.cos(rad(position2.lat)) *
      Math.sin(distanceLong / 2) *
      Math.sin(distanceLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthMeanRadius * c
}

export const distanceFilter = ({
  position1,
  position2,
  distance,
}: DistanceFilterType): boolean => {
  if (
    typeof position1?.lat !== 'number' ||
    typeof position1?.lng !== 'number' ||
    typeof position2?.lat !== 'number' ||
    typeof position2?.lng !== 'number'
  ) {
    return false
  }

  return getDistance(position1, position2) <= distance
}

export const sortByDistance = (
  position1: PositionTypes,
  position2: PositionTypes,
  deviceLocation: PositionTypes,
): number => {
  const distance1 = getDistance(deviceLocation, position1)
  const distance2 = getDistance(deviceLocation, position2)
  return distance1 - distance2
}

export default getDistance

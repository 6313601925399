import React, { memo, ReactElement } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import './MiniArticleCard.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'

type MiniArticleCardPropTypes = {
  title: string
  image: ImageDataLike | string
  type: string
  date: string
  slug: string
  className?: string
}

const MiniArticleCard = ({
  title,
  image,
  type,
  date,
  slug,
  className,
}: MiniArticleCardPropTypes): ReactElement => {
  return (
    <Location>
      {({ location }) => {
        const loc = location.pathname
        const url = loc.split('/')[1]
        return (
          <Link
            to={`/${
              url === 'pursuit-of-passion'
                ? 'pursuit-of-passion'
                : 'news-and-events'
            }/${slug}/`}
            className="mini-article-card-link"
          >
            <div
              className={`mini-article-card${className ? ` ${className}` : ''}`}
            >
              {typeof image === 'string' ? (
                <img src={image} alt="article image" />
              ) : (
                <GatsbyImage image={getImage(image)!} alt="article image" />
              )}
              <div className="mini-article-card-details">
                <span>{type}</span>
                <h3>{title}</h3>
                <p>{date}</p>
              </div>
            </div>
          </Link>
        )
      }}
    </Location>
  )
}

export default memo(MiniArticleCard)

import React, { ReactElement, memo } from 'react'
import './ArticleLayout.scss'
import { ImageDataLike } from 'gatsby-plugin-image'
import MiniArticleCard from '../../components/MiniArticleCard/MiniArticleCard'
import Breadcrumbs, {
  BreadcrumbsTypes,
} from '../../components/Breadcrumbs/Breadcrumbs'
import ContentNavigation, {
  ContentNavigationTypes,
} from '../../components/ContentNavigation/ContentNavigation'
import VerticalNavigation, {
  NavItemTypes,
} from '../../components/VerticalNavigation/VerticalNavigation'

export type RecentArticleTypes = {
  id: string
  title: string
  slug: string
  category: string
  datePublished: string
  featuredImage: string
  optimizedFeaturedImg?: ImageDataLike
}

type ArticleLayoutPropTypes = {
  title?: string
  asideNavItems: NavItemTypes[]
  breadcrumbs?: BreadcrumbsTypes[]
  contentNavigation?: {
    navigations: ContentNavigationTypes[]
    navigationPrefix: string
  }
  recentArticles: RecentArticleTypes[]
  displayRecentArticlesMobile?: boolean
  className?: string
  contentClassName?: string
  asideArticleTitle?: string
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
}

const ArticleLayout = ({
  title,
  asideNavItems,
  breadcrumbs,
  contentNavigation,
  recentArticles,
  displayRecentArticlesMobile,
  className,
  contentClassName,
  asideArticleTitle,
  children,
}: ArticleLayoutPropTypes): ReactElement => {
  return (
    <div
      className={`${className ? `${className} ` : ''}article-layout-container`}
    >
      <aside className="article-layout-aside">
        <div className="article-layout-aside-header">
          {!!title && (
            <h3 className="article-layout-aside-header-title">{title}</h3>
          )}
          <VerticalNavigation
            navigations={asideNavItems}
            className="article-layout-aside-header-links"
          />
        </div>
        <div className="article-layout-recent-articles">
          <h3 className="article-layout-recent-articles-title">
            {asideArticleTitle || 'Recent Post'}
          </h3>
          {recentArticles.map(
            ({
              id,
              slug,
              title: articleTitle,
              category,
              datePublished,
              optimizedFeaturedImg,
              featuredImage,
            }) => (
              <MiniArticleCard
                key={id}
                slug={slug}
                title={articleTitle}
                image={optimizedFeaturedImg || featuredImage}
                type={category}
                date={datePublished}
                className="item"
              />
            ),
          )}
        </div>
      </aside>
      <main className="article-layout-main">
        {!!contentNavigation && (
          <ContentNavigation
            navigationPrefix={contentNavigation.navigationPrefix}
            navigations={contentNavigation.navigations}
          />
        )}
        <div
          className={`article-layout-main-content${
            contentClassName ? ` ${contentClassName}` : ''
          }`}
        >
          {!!breadcrumbs && (
            <Breadcrumbs
              items={breadcrumbs}
              className="article-layout-main-content-breadcrumbs"
            />
          )}
          {children}
        </div>
        {!!displayRecentArticlesMobile && (
          <div className="article-layout-recent-articles mobile-visible">
            <h3 className="article-layout-recent-articles-title">
              {asideArticleTitle || 'Recent Post'}
            </h3>
            {recentArticles.map(
              ({
                id,
                slug,
                title: articleTitle,
                category,
                datePublished,
                optimizedFeaturedImg,
                featuredImage,
              }) => (
                <MiniArticleCard
                  key={id}
                  slug={slug}
                  title={articleTitle}
                  image={optimizedFeaturedImg || featuredImage}
                  type={category}
                  date={datePublished}
                  className="item"
                />
              ),
            )}
          </div>
        )}
      </main>
    </div>
  )
}

export default memo(ArticleLayout)

import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import './OurNeighborhood.scss'
import Layout from '../../layouts/Layout/Layout'
import ArticleLayout from '../../layouts/ArticleLayout/ArticleLayout'
import Seo from '../../components/Seo/Seo'
import { distanceFilter } from '../../services/getDistance'
import { isMP4 } from '../../services/validations'
import loadable from '@loadable/component'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

const ArticleCard = loadable(() =>
  import('../../components/ArticleCard/ArticleCard'),
)

const DISTANCE_RADIUS = 10000

type PositionTypes = {
  lat: number
  lng: number
}

type NeighborhoodTypes = {
  seoTitle: string
  seoDescription: string
  title: string
  slug: string
  featuredImage: string
  videoLink: string
  shortDescription: string
  optimizedFeaturedImg: ImageDataLike
  homeBanner: {
    image: string
    videoLink: string
  }
  position: PositionTypes
  tags: string[]
}

export type ArticleTypes = {
  id: string
  title: string
  featuredImage: string
  optimizedFeaturedImg: ImageDataLike
  category: string
  datePublished: string
  position: PositionTypes
  slug: string
  shortDescription?: string
  tags: string[]
}

type NavigationTypes = {
  label: string
  url: string
}

type MainPropTypes = {
  data: {
    ourNeighborhood: NeighborhoodTypes
    allNeighborhood: {
      nodes: NavigationTypes[]
    }
    featuredPursuitOfPassion: {
      nodes: ArticleTypes[]
    }
    pursuitOfPassionList: {
      nodes: ArticleTypes[]
    }
    recentArticles: {
      nodes: ArticleTypes[]
    }
  }
}

const OurNeighborhood = ({
  data: {
    ourNeighborhood,
    allNeighborhood: { nodes: allNeighborhood },
    featuredPursuitOfPassion: { nodes: featuredPursuitOfPassion },
    pursuitOfPassionList: { nodes: pursuitOfPassionList },
    recentArticles: { nodes: recentArticles },
  },
}: MainPropTypes): ReactElement => {
  const [featuredArticleList] = useState(
    featuredPursuitOfPassion
      .filter((featured) =>
        distanceFilter({
          position1: ourNeighborhood.position,
          position2: featured.position,
          distance: DISTANCE_RADIUS,
        }),
      )
      .slice(0, 1),
  )

  const [articleList] = useState(
    pursuitOfPassionList.filter((featured) =>
      distanceFilter({
        position1: ourNeighborhood.position,
        position2: featured.position,
        distance: DISTANCE_RADIUS,
      }),
    ),
  )

  const seoTitle =
    ourNeighborhood.seoTitle && ourNeighborhood.seoTitle !== 'no content'
      ? ourNeighborhood.seoTitle
      : ourNeighborhood.title

  const seoDescription =
    ourNeighborhood.seoDescription &&
    ourNeighborhood.seoDescription !== 'no content'
      ? ourNeighborhood.seoDescription
      : ourNeighborhood.shortDescription

  return (
    <Layout navButtonVariant="back" returnPageUrl="/neighborhoods/">
      <Seo
        title={seoTitle}
        ogMetaData={{
          title: seoTitle,
          description: seoDescription,
          image: ourNeighborhood.featuredImage,
        }}
        jsonData={{
          '@type': 'Article',
          description: seoDescription,
          keywords: ourNeighborhood.tags.join(', '),
          image: ourNeighborhood.featuredImage,
          url: `${process.env.GATSBY_SITE_URL}/neighborhoods/${ourNeighborhood.slug}/`,
        }}
      />
      <ArticleLayout
        title="Our Neighborhood"
        asideNavItems={allNeighborhood}
        asideArticleTitle="News & Events"
        breadcrumbs={[
          {
            url: 'neighborhoods',
            label: 'Our Neighborhood',
          },
          {
            label: ourNeighborhood.title,
          },
        ]}
        recentArticles={recentArticles
          ?.filter((article) => !article.tags?.includes('banner'))
          ?.slice(0, 3)}
        contentClassName="our-neighborhood "
      >
        <article className="our-neighborhood">
          <div className="our-neighborhood-featured">
            {isMP4(ourNeighborhood?.featuredImage) ? (
              <div className="featured-video-banner">
                <video autoPlay muted loop>
                  <source
                    src={ourNeighborhood?.featuredImage}
                    type="video/mp4"
                  />
                </video>
              </div>
            ) : (
              <GatsbyImage
                image={getImage(ourNeighborhood.optimizedFeaturedImg)!}
                alt="featured image"
              />
            )}
            <div className="our-neighborhood-overview">
              <p className="description">{ourNeighborhood?.shortDescription}</p>
            </div>
          </div>
          {articleList.length ? (
            <div className="our-neighborhood-container">
              <h2 className="our-neighborhood-header">Our Neighborhood</h2>
              <h2 className="our-neighborhood-location">
                {ourNeighborhood.title}
              </h2>

              <div className="our-neighborhood-content">
                {articleList.map((article) => (
                  <ArticleCard
                    key={article.id}
                    data={article}
                    url={`/pursuit-of-passion/${article.slug}/`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NoResult />
          )}
        </article>
      </ArticleLayout>
    </Layout>
  )
}
export default OurNeighborhood

export const pageQuery = graphql`
  query NeighborhoodQuery($id: String!) {
    ourNeighborhood: neighborhood(id: { eq: $id }) {
      ...OurNeighborhoodPageArticleFields
    }
    allNeighborhood {
      nodes {
        label: title
        url: slug
      }
    }
    featuredPursuitOfPassion: allPursuitOfPassion(
      filter: { featured: { eq: true } }
    ) {
      nodes {
        ...OurNeighborhoodPursuitOfPassionCardFields
      }
    }
    pursuitOfPassionList: allPursuitOfPassion {
      nodes {
        ...OurNeighborhoodPursuitOfPassionCardFields
      }
    }
    recentArticles: allNewsAndEvents {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
